import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollTo() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash !== '') {
      const lh1 = location.hash.slice(1);
      if (lh1 && document.getElementById(lh1)) {
        document.getElementById(lh1).scrollIntoView({behavior: 'smooth'});
        window.history.replaceState(null,'',location.pathname);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
}

export default ScrollTo;
