const autofillStyle = {
    singleValue: (provided) => ({
      ...provided,
      alignItems: "center",
    }),
  
    control: (base, state) => ({
      ...base,
      outline: "transparent",
      border: "transparent",
      background: "transparent",
      boxSizing: "border-box",
      padding: "8px 15px",
      width: "100%",
      minHeight: "34px",
      boxShadow: "none",
      "&:hover": { borderColor: "none", outline: "none" },
    }),
  
    loadingIndicator: (base) => ({
      ...base,
      marginRight: 30,
    }),
  
    valueContainer: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      color: "#352C64",
      cursor: "text",
    }),
  
    input: (base) => ({
      ...base,
      color: "#352C64",
      margin: 0,
      padding: 0,
    }),
  
    placeholder: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
  
    //main container for artist search popup
    menu: (base, state) => ({
      ...base,
      borderColor: state.selectProps.inputValue.length
        ? "#B2A8D5"
        : "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      outline: "none",
      boxSizing: "border-box",
      boxShadow: "none",
      borderRadius: "20px",
      overflow: "hidden",
      margin: "5px 0 0 0",
      backgroundColor: state.selectProps.inputValue.length
        ? "#1A1632"
        : "transparent",
      width: state.selectProps.inputValue.length ? "100%" : "0",
    }),
  
    option: (base) => ({
      ...base,
      backgroundColor: "transparent",
      padding: "0",
      margin: "0",
      "&:active": { backgroundColor: "transparent" },
    }),
  
    menuList: (base) => ({
      ...base,
      padding: "0px",
      margin: "0px 5px 0px 0px",
      color: "#fff",
    }),
  };
  
  export default autofillStyle;  