import React, { Component } from "react";
import "./Input.scss";

class Input extends Component {
	constructor() {
		super();
		this.state = {
			value: ''
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		this.setState({value: e.target.value},() => {
			this.props.return(this.props.keyName,this.state.value);
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.clear && this.props.clear && this.state.value !== '') {
			this.setState({value: ''},() => {
				this.props.return(this.props.keyName,this.state.value);
			})
		}
	}

	render() {
		return (
			<div className="input">
				<div className="input-label">{this.props.label}</div>
				<input
					className="input-input"
					type={this.props.type || "text"}
					placeholder={this.props.placeholder || ""}
					value={this.state.value}
					onChange={(e) => this.handleChange(e)} />
			</div>
		)
	}
}

export default Input;