const timeStamp = new Date().getTime();

export const WIDGET_RENDER = `
<head>
    <meta http-equiv='cache-control' content='no-cache'>
    <meta http-equiv='expires' content='0'>
    <meta http-equiv='pragma' content='no-cache'>
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/chunk.css?t=${timeStamp}" rel="stylesheet" />
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/main.chunk.css?t=${timeStamp}" rel="stylesheet" />
    <style>
        .getFundedContainer {
            padding: 0;
        }
        .select__input input {
            letter-spacing: -0.3px;
        }
        .getFundedHeaderText {
            font-size:23px !important;
        }
    </style>
</head>
<body>
    <div id="getFunded" class="getFundedContainer"></div>
    <script async>
        window.GET_FUNDED_API_URL = "${process.env.REACT_APP_API_URL}";
        window.WIDGET_HEADLINE = "See Funding Options"; // Here you can also give widget header title according to whitelabel 
    </script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/chunk.js?t=${timeStamp}" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/main.chunk.js?t=${timeStamp}" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/runtime-main.js?t=${timeStamp}" async></script>
    <script
        type="text/javascript"
        id="hs-script-loader"
        async defer src="//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js"
      ></script>
</body>
`;
