import React from "react";
import styles from "./Pagination.module.scss";
import {ReactComponent as RadioButtonChecked} from "../../assets/SVGs/Radio_Button_Checked.svg";
import {ReactComponent as RadioButtonUnchecked} from "../../assets/SVGs/Radio_Button_Empty.svg";

function Pagination(props) {

	return (
		<div className={`${styles.pagination}`} id="pagination">
            {Array.from({length: props.numberOfPages}).map((e, i) => {
              return (
                <div key={i} className={styles.clOption}>
				 	{(i+1) === props.currentPage ? (
						<RadioButtonChecked className={styles.checked} />
					) : (
						<RadioButtonUnchecked className={styles.unchecked}/>
					)}
                </div>
              );
            })}
		</div>
	)
}

/* Example of Use:

	<Pagination
		numberOfPages={this.state.NumberOfSteps}
		currentPage={this.state.currentStep}
	/>
	
*/

export default Pagination;