import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PRIMARY_NAVIGATION, SECONDARY_NAVIGATION } from "./constants";
import styles from "./HeaderV3.module.scss";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBreadLogoResponsive.svg";
import { ARTIST_ENROLLMENT, FUNDING_NETWORK } from "../../routes/constants";
import { Box, Drawer } from "@mui/material";
import { ReactComponent as Hamburger } from "../../assets/SVGs/hamburger-menu.svg";
import { ReactComponent as Xclose } from "../../assets/SVGs/x-close.svg";

class HeaderV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  toggleMenu = (override) => {
    this.setState({ showMenu: override });
  };

  renderPrimaryNavbar = () => {
    return PRIMARY_NAVIGATION.map((item, i) => (
      <Link
        to={item.to}
        key={i}
        className={`${styles.primary} ${styles.navLink}`}
      >
        {item.title}
      </Link>
    ));
  };

  renderSecondaryNavbar = () => {
    return (
      <>
        {SECONDARY_NAVIGATION.map((item, i) => (
          <Link
            to={item.to}
            key={i}
            className={`${styles.secondary} ${styles.navLink}`}
          >
            {item.title}
          </Link>
        ))}
      </>
    );
  };

  list = () => (
    <Box role="presentation" onClick={() => this.toggleMenu(false)}>
      <div className={styles.mobilePrimaryNav}>
        {this.renderPrimaryNavbar()}
      </div>
      <div>
        {this.renderSecondaryNavbar()}
        <div className={styles.mobileLoginContainer}>
          <Link to={`${FUNDING_NETWORK}`} className={styles.mobileLogin}>
            Sign Up
          </Link>
          <Link
            to={`${ARTIST_ENROLLMENT}?isLogin=true`}
            className={styles.mobileLogin}
          >
            Login
          </Link>
        </div>
      </div>
    </Box>
  );

  render() {
    return (
      <>
        <div className={styles.mainNavContainer}>
          <div className={`${styles.navbar} ${styles.navbarHome}`}>
            <div className={styles.primaryNavbarContainer}>
              <div className={styles.navbarLeft}>
                <div className={styles.navbarLogo}>
                  <Link to={this.props.home} className={styles.navLinkA}>
                    <BeatBreadLogo />
                  </Link>
                </div>
              </div>
              <div className={styles.navbarRight}>
                <div className={styles.navLinksContainer}>
                  {this.renderSecondaryNavbar()}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.secondaryNavContainer}>
            <div className={styles.navLinkContainer}>
              <div className={styles.navLinkLeft}>
                {this.renderPrimaryNavbar()}
              </div>
              <div className={styles.navLinkRight}>
                <Link to={`${FUNDING_NETWORK}`} className={styles.loginLink}>
                  Sign Up
                </Link>
                <Link
                  to={`${ARTIST_ENROLLMENT}?isLogin=true`}
                  className={styles.loginLink}
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.navMobileMenuHeader}>
            <Link to={this.props.home} className={styles.navMobileLogo}>
              <BeatBreadLogo />
            </Link>
            <div
              className={styles.navMobileMenuButton}
              onClick={() => this.toggleMenu(!this.state.showMenu)}
            >
              {this.state.showMenu ? <Xclose /> : <Hamburger />}
            </div>
          </div>
          <Drawer
            variant="persistent"
            anchor={"top"}
            open={this.state.showMenu}
            onClose={() => {
              this.toggleMenu(false);
            }}
            className={`${styles.mobileDrawer} ${
              this.state.showMenu ? styles.open : ""
            }`}
          >
            {this.list()}
          </Drawer>
        </div>
      </>
    );
  }
}

export default HeaderV3;
