import base from "base-64";
import { get } from "lodash";

const getDocument = () =>
  typeof document === "undefined"
    ? {
        querySelector() {
          return null;
        },
      }
    : document;

const StorageService = {
  exists(key) {
    return this.get(key);
  },

  set(key, value, opts = {}) {
    let storedValue = value;
    if (opts.stringify) {
      storedValue = JSON.stringify(storedValue);
    }
    if (opts.hash) {
      storedValue = base.encode(storedValue);
    }
    try {
      if (!opts.localStorage) {
        const d = new Date();
        d.setTime(d.getTime() + 365 * 100 * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        getDocument().cookie = `${key}=${JSON.stringify({
          opts,
          body: encodeURIComponent(storedValue),
        })};${expires};path=/`;
      }

      localStorage.setItem(
        key,
        JSON.stringify({
          opts,
          body: storedValue,
        })
      );
    } catch (err) {
      throw err;
    }
  },

  get(key, opts = {}) {
    const data = JSON.parse(localStorage.getItem(key));
    if (opts.localStorage && data) {
      let { body } = data;
      if (data.opts && data.opts.hash) body = base.decode(body);
      if (data.opts && data.opts.stringify) body = JSON.parse(body);
      return body;
    }
    if (!opts.localStorage) {
      const name = `${key}=`;
      const decodedCookie = getDocument().cookie;
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          const data = JSON.parse(c.substring(name.length, c.length));
          let { body } = data;
          body = decodeURIComponent(body);
          if (data.opts && data.opts.hash) {
            body = base.decode(body);
          }
          if (data.opts && data.opts.stringify) {
            body = JSON.parse(body);
          }
          return body;
        }
      }
      return false;
    }
    return false;
  },

  delete(key, opts = {}) {
    const d = new Date();
    getDocument().cookie = `${key}=${JSON.stringify({
      opts,
      body: "",
    })}; path=/; expires=${d};`;
    localStorage.removeItem(key);
  },
  clear() {
    get(getDocument(), "cookie", "")
      .split(";")
      .forEach((cookie) => {
        const idx = cookie.indexOf("=");
        const name = idx > -1 ? cookie.substring(0, idx) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      });
    localStorage.clear();
  },
};

export default StorageService;
