import "whatwg-fetch";
import AuthTokenService from "./AuthTokenService";
import StorageService from "./StorageService";
import { userExists } from "./helper";

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (
    response.status === 204 ||
    response.status === 205 ||
    response.status === 304 ||
    response.status === 401
  ) {
    return null;
  }
  return response.json();
}
function parseBlob(response) {
  const httpStatus = [204, 205, 304, 401];
  if (httpStatus.indexOf(response.status) !== -1) {
    return null;
  }
  return response.blob();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 304) {
    return response;
  }
  if (response.status === 401) {
    return response;
  }
  if (response.status === 403) {
    return response;
  }
  if (
    response.status === 422 ||
    response.status === 400 ||
    response.status === 500 ||
    response.status === 504 ||
    response.status === 411
  ) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options, useDefaultToken = true) {
  const option = {
    method: options.method,
    headers: options.headers
      ? options.headers
      : {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
  };
  if (options.body) {
    if (options.headers) {
      option.body = options.body;
    } else {
      option.body = JSON.stringify(options.body);
    }
  }
  if (userExists()) {
    option.headers.Authorization = useDefaultToken
      ? `${AuthTokenService.get()}`
      : `${StorageService.get("customToken")}`;
  }
  if (options.blob) {
    return fetch(url, option).then(checkStatus).then(parseBlob);
  }
  return fetch(url, option).then(checkStatus).then(parseJSON);
}
