// Breakpoints
export const XS = 0;
export const XS_SM = 288;
export const SM = 576;
export const SM_MD = 672;
export const MD = 768;
export const MD_LG = 908;
export const LG = 1048;
export const LG_XL = 1224;
export const XL = 1400;
export const XL_XXL = 1650;
export const XXL = 1900;
export const XXL_XK = 2200;
export const XK = 2500;

export const MOBILE_BREAK = XL;

export const WIDGET_RENDER = `
<head>
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/chunk.css" rel="stylesheet" />
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/main.chunk.css" rel="stylesheet" />
    <style>
        .getFundedContainer {
            padding: 0;
        }
        .select__input input {
            letter-spacing: -0.3px;
        }
    </style>
</head>
<body>
    <div id="getFunded" class="getFundedContainer"></div>
    <script async>
        window.GET_FUNDED_API_URL = "${process.env.REACT_APP_API_URL}";
        window.WIDGET_HEADLINE = "Sign up";
    </script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/chunk.js" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/main.chunk.js" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/runtime-main.js" async></script>
</body>
`;
