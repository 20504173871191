import AuthTokenService from "./AuthTokenService";

export function userExists() {
  if (AuthTokenService.exists()) {
    AuthTokenService.init();
    return true;
  }
  return false;
}

export const numberWithCommas = (num = 0) =>
  num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
