import React, { useState } from "react";
import styles from "./Dropdowns.module.scss";
import { Link } from "react-router-dom";
import { Scroller } from "..";
import { ReactComponent as Arrow } from "../../assets/SVGs/navbar-arrow-2.svg";
import { ReactComponent as NextArrow } from "../../assets/SVGs/nextArrow.svg";

function DDLink(props) {
  const [subShow, setSubShow] = useState(
    Array.isArray(props.data)
      ? Object.fromEntries(props.data.map((e) => [e.title, false]))
      : false
  );
  const subToggleMenu = (override, index) => {
    if (index) {
      const newState = {
        ...(override ? stateReset : subShow),
        [index]: override || !subShow[index],
      };
      setSubShow(newState);
    } else {
      setSubShow(override || !subShow);
    }
  };
  const stateReset = (inState) => {
    return Object.fromEntries(inState.map((e) => [e.title, false]));
  };
  const renderArrow = () => (props?.arrow ? <Arrow /> : <></>);
  const renderTitle = () =>
    props.to ? (
      <Link to={props.to} className={styles.title}>
        {props.title}
      </Link>
    ) : (
      <div
        className={styles.title}
        onMouseOver={() => props.onMouseOver(true, props.title)}
      >
        {props.title}
      </div>
    );
  const renderSubDropdown = () =>
    props.links?.length &&
    subShow[props.name] && (
      <div className={`${styles.subDropdown}`}>
        <Dropdowns data={props.links} subShow={subShow} />
      </div>
    );
  return (
    <>
      {props.title ? (
        <div className={styles.titleContainer}>
          {renderTitle()}
          {renderArrow()}
        </div>
      ) : props?.to?.slice(0, 1) === "#" ? (
        <Scroller scrollID={props.to} href={props.home} className={styles.item}>
          <div>{props.name}</div>
        </Scroller>
      ) : (
        <div onMouseLeave={() => subToggleMenu(false, props.name)}>
          <Link to={props.to} className={styles.item}>
            <div
              onMouseOver={() => {
                if (props.links?.length) {
                  subToggleMenu(true, props.name);
                }
              }}
              className={styles.link}
            >
              {props.name}
              {props?.arrow1 && <NextArrow />}
            </div>
          </Link>
          {renderSubDropdown()}
        </div>
      )}
    </>
  );
}

function Dropdowns(props) {
  const [show, setShow] = useState(
    Array.isArray(props.data)
      ? Object.fromEntries(props.data.map((e) => [e.title, false]))
      : false
  );

  const stateReset = (inState) => {
    return Object.fromEntries(inState.map((e) => [e.title, false]));
  };

  const toggleMenu = (override, index) => {
    if (index) {
      const newState = {
        ...(override ? stateReset : show),
        [index]: override || !show[index],
      };
      setShow(newState);
    } else {
      setShow(override || !show);
    }
  };

  return (
    <>
      <div className={styles.dropdowns}>
        {props?.data?.map((e, i) => (
          <>
            <div key={i} className={styles.dropdown}>
              <DDLink
                title={e.title || e.name}
                to={e.to}
                onMouseOver={toggleMenu}
                arrow={e.arrow}
                home={props.home}
              />

              {e.links?.length > 0 && show[e.title] && (
                <div
                  className={styles.menuContainer}
                  onMouseLeave={() => toggleMenu(false, e.title)}
                >
                  <div className={`${styles.menu} `}>
                    {e.links.map((ee, ii) => (
                      <div className={styles.subMenu}>
                        <DDLink
                          key={ii}
                          to={ee.to}
                          name={ee.name}
                          href={ee.href}
                          links={ee?.links}
                          arrow1={ee?.arrow1}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )) || <></>}

        {/* {
				props.items?.length > 0 && show ?
					<div
						className="dd-menu-container"
						onMouseLeave={() => toggleMenu(false)}
					>
						<div className="dd-menu">
							{
								props.items.map((e,i) => (
									<React.Fragment key={i}>
										{
											e.to.slice(0,1) === '#' ?
												<Scroller scrollID={e.to} href={HOME} className="dd-menu-item">{e.name}</Scroller>
											:
												<Link key={i} to={e.to} className="dd-menu-item">{e.name}</Link>
										}
									</React.Fragment>
								))
							}
						</div>
					</div>
				:
					<></>
			} */}
      </div>
    </>
  );
}

export default Dropdowns;
