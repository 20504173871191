import React, { useEffect } from "react";
import "./A.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Scroller(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const cleanID = props.scrollID.slice(0,1) === "#" ? props.scrollID.slice(1) : props.scrollID;

	const handleClick = () => {
		if (props.href) {
			if (location.pathname !== props.href) {
				navigate(`${props.href}${props.scrollID}`);
				
			} else {
				document.getElementById(cleanID).scrollIntoView();
			}
		} else {
			document.getElementById(cleanID).scrollIntoView();
		}
	}
	
	useEffect(() => {
		if (location.hash === `#${cleanID}`) {
			document.getElementById(cleanID).scrollIntoView();
		}
	});
	return (
		<div className={`custom-link-scroller ${props.className || ""}`} style={props.style || {}} onClick={() => handleClick()}>
			{props.children}
		</div>
	)
}

function ALink(props) {

	return (
		<Link
			className={`custom-link ${props.className || ""}`}
			style={props.style || {}}
			to={props.to || props.href || ""}
		>
			{props.children || props.value || ""}
		</Link>
	);
}

function Aa(props) {

	return (
		<a
			className={`custom-link ${props.className || ""}`}
			style={props.style || {}}
			href={props.to || props.href || ""}
			alt={props.alt || ""}
		>
			{props.children || props.value || ""}
		</a>
	);
}

function ADefault(props) {
	const navigate = useNavigate();

	const handleClick = () => {
		if (props.onClick) {
			props.onClick();
		}
		navigate(props.to);
	}

	return (
		<div
			className={`custom-link ${props.className || ""}`}
			style={props.style || {}}
			onClick={() => handleClick()}
		>
			{props.children || props.value || ""}
		</div>
	);
}


function A(props) {

	const checkPound = (inString) => {
		if (inString && inString.slice(0,1) === '#') {
			return {found: true, id: inString, clean: inString.slice(1), path: `/${inString.slice(1)}`};
		} else if (inString && inString.slice(0,1) === '/') {
			return {found: false, id: `#${inString.slice(1)}`, clean: inString.slice(1), path: inString};
		} else {
			return {found: false, id: `#${inString || ""}`, clean: inString || "", path: `/${inString || ""}`};
		}
	}

	const isScroller = (inURL) => {
		const url = props.to || inURL;
		const href = props.href || "/";
		const urlPound = checkPound(url);
		const hrefPound = checkPound(href);
		if (props.scroller || (url && (urlPound.found || hrefPound.found))) {
			if ((urlPound.found && !hrefPound.found) || (urlPound.found && hrefPound.found)) {
				return {verdict: true, path: hrefPound.path, id: urlPound.id}
			} else if (!urlPound.found && hrefPound.found) {
				return {verdict: true, path: urlPound.path, id: hrefPound.id}
			}
		} else {
			return {verdict: false, path: '', id: ''};
		}
	}

	const checkScroller = isScroller();

	return (
		<>
			{
				checkScroller.verdict ?
				<Scroller
					className={props.scrollerClassName || ""}
					style={props.scrollerStyle || {}}
					scrollID={checkScroller.id}
					href={checkScroller.path}
				>
					{
						props.link ?
							<ALink {...props} />
						: props.a ? 
							<Aa {...props} />
						:
							<ADefault {...props} />
					}
				</Scroller>
				:
				<>
					{
						props.link ?
							<ALink {...props} />
						: props.a ? 
							<Aa {...props} />
						:
							<ADefault {...props} />
					}
				</>
			}
		</>
	)
}

export default A;