export const EMAIL_REGEX =
  /^[A-Za-z0-9_+#*&?$!.-]+@[A-Za-z0-9_+#*&?$!.-]+\.[A-Za-z]{2,}$/;

export const BEATBREAD_PRIVACY_POLICY = "https://app.beatbread.com/privacy";
export const BEATBREAD_TERM_OF_USE = "https://www.beatbread.com/terms_of_use";

export const FORBIDDEN = "forbidden";
export const ACK_PAGE = "acknowledgementPage";
export const USER_ALREADY_REGISTERED = "USER_ALREADY_REGISTERED";
export const NEW_USER_REGISTERED = "NEW_USER_REGISTERED";
export const USER_REGISTERED_NEW_ARTIST_ADDED =
  "USER_REGISTERED_NEW_ARTIST_ADDED";
export const USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED =
  "USER_ALREADY_REGISTERED_ARTIST_ALREADY_LINKED";
export const USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED =
  "USER_ALREADY_REGISTERED_NEW_ARTIST_ADDED";
export const MAX_ARTIST_LINKED = "MAX_ARTIST_LINKED";
export const MAX_ARTIST_LINKED_NOT_REGISTERED =
  "MAX_ARTIST_LINKED_NOT_REGISTERED";
export const ARTIST_ALREADY_LINKED = "ARTIST_ALREADY_LINKED";
export const ERRORED = "ERRORED";
export const PENDING = "OFFER_PENDING";
export const MAINTENANCE = "maintenance";
export const LOGIN_SCREEN = "LOGIN_SCREEN";
export const STEP_1 = "step-1";
export const STEP_2 = "step-2";
export const STEP_3 = "step-3";
export const STEP_4 = "step-4";
export const STEP_5 = "step-5";

export const STEP = "step";

export const INCOME_OPTIONS = [
  {
    label: "Less than $2k",
    value: "Less than $2k",
  },
  {
    label: "$2k-$5k",
    value: "$2-$5k",
  },
  {
    label: "$5k-$10k",
    value: "$5-$10k",
  },
  {
    label: "$10k-$25k",
    value: "$10k-$25k",
  },
  {
    label: "$25k-$50k",
    value: "$25k-$50k",
  },
  {
    label: "Greater than $50k",
    value: "Greater than $50k",
  },
];

export const OVERALL_STATUS = [
  "Report sent successfully",
  "failed_reports",
  "failed_tracks",
  "error",
  "no_reports_processed",
];
