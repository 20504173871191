import React from "react";
import {
  VISIBILITY,
  VISIBILITY_OFF,
  PROFILE_ICON,
  LEFT_ARROW_ICON,
  BB_LOGO,
  TOO_BIG_GRAPH,
  TOO_SMALL,
  LOGIN_ICON,
  EMAIL_ICON,
  BB_ICON,
  REST_PASSWORD,
} from "./constants";
import { ReactComponent as AccountCircle } from "../../assets/SVGs/account_circle.svg";
import { ReactComponent as VisibilityOff } from "../../assets/SVGs/visibility_off.svg";
import { ReactComponent as Visibility } from "../../assets/SVGs/visibility.svg";
import { ReactComponent as LeftArrow } from "../../assets/SVGs/arrowLeftIcon.svg";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBreadLogo.svg";
import { ReactComponent as TooBigGraph } from "../../assets/SVGs/tooBigGraphIcon.svg";
import { ReactComponent as TooSmall } from "../../assets/SVGs/tooSmallIcon.svg";
import { ReactComponent as LoginIcon } from "../../assets/SVGs/loginIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/SVGs/emailIcon.svg";
import { ReactComponent as ResetIcon } from "../../assets/SVGs/forgotPasswordIcon.svg";
import { ReactComponent as BBIcon } from "../../assets/SVGs/logos/bbForgotPassordIcon.svg";

const SvgIcons = ({ icon, className, ...otherProps }) => {
  const getIconComponent = (Component) => (
    <Component className={className} {...otherProps} />
  );

  switch (icon) {
    case VISIBILITY:
      return getIconComponent(Visibility);
    case VISIBILITY_OFF:
      return getIconComponent(VisibilityOff);
    case PROFILE_ICON:
      return getIconComponent(AccountCircle);
    case LEFT_ARROW_ICON:
      return getIconComponent(LeftArrow);
    case BB_LOGO:
      return getIconComponent(BeatBreadLogo);
    case TOO_BIG_GRAPH:
      return getIconComponent(TooBigGraph);
    case TOO_SMALL:
      return getIconComponent(TooSmall);
    case LOGIN_ICON:
      return getIconComponent(LoginIcon);
    case EMAIL_ICON:
      return getIconComponent(EmailIcon);
    case REST_PASSWORD:
      return getIconComponent(ResetIcon);
    case BB_ICON:
      return getIconComponent(BBIcon);
    default:
      return null;
  }
};

export default SvgIcons;
