import { useLocation } from "react-router-dom";
import {
  HOME,
  AGENT_REGEX,
  HOME_AGENT_REGEX,
} from "../routes/constants";

function useAgent(override) {
  const location = useLocation();
  const lsHome = sessionStorage.getItem('home');

  const pathname = override || location.pathname;

  if (lsHome) {
    if ((lsHome === HOME && AGENT_REGEX.test(pathname)) || (lsHome !== pathname && AGENT_REGEX.test(pathname))) {
      sessionStorage.setItem('home',pathname);
      return pathname;
    } else {
      return lsHome;
    }
  } else {
    if (HOME_AGENT_REGEX.test(pathname)) {
      sessionStorage.setItem('home',pathname);
      return pathname;
    } else {
      return lsHome || HOME;
    }
  }
}

export default useAgent;