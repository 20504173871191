import React, { Component } from "react";
import styles from "./FFLogins.module.scss";
import SvgIcons from "../MaterialIcons/SvgIcons";
import { EMAIL_ICON } from "../MaterialIcons/constants";
import { ReactComponent as LOGIN_ICON} from "../../assets/SVGs/hand-wave.svg";
import Button2 from "../Button2";
import base from "base-64";
import { Form, Formik } from "formik";
import FormField from "../FormField/FormField";
import { EMAIL_REGEX } from "../OnboardingWidget/constant";
import * as Yup from "yup";
import { PASS_NUM_SPECIAL_REGEX, PASS_UPPER_LOWER_REGEX } from "./constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import request from "../../utils/request";
import {
  API_URL,
  ARTIST_SEARCH_ESTIMATES,
  AUTH,
  FUNDING_NETWORK,
  LOGIN,
  SIGNIN_API,
  SIGNIN_SECURE_API,
} from "../../routes/constants";
import StorageService from "../../utils/StorageService";
import { toast } from "react-toastify";
import { get } from "lodash";
import segment from "../../utils/segment";
import { getInstance } from "../SearchBar/helper";
import { Link } from "react-router-dom";

class FFLogins extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      isMailSent: false,
      updatedFormData: {
        email: this.props.emailProps || "",
        password: "",
      },
      instanceData: {}
    };
  }
  
  passwordValidate = Yup.string()
    .required()
    .min(8, "Please enter valid password between 8 to 20 characters")
    .max(20, "Please enter valid password between 8 to 20 characters")
    .matches(
      PASS_UPPER_LOWER_REGEX,
      "Password should have at least 1 uppercase letter & 1 lowercase letter."
    )
    .matches(
      PASS_NUM_SPECIAL_REGEX,
      "Password should have at least 1 number & 1 special character."
    )
    .label("Password");

  validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
  });

  validationLoginSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    password: this.passwordValidate,
  });

  componentDidMount () {
    getInstance.call(this);
  }

  handleSubmit = (values) => {
    this.setState({ loading: true, updatedFormData: values }, () => {
      const { isSecureLogin } = this.props;
      if (isSecureLogin) {
        this.submitSecureLogin(values);
      } else {
        this.submitLogin(values);
      }
    });
  };

  submitSecureLogin = (values) => {
    const payload = {
      email: values.email,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${SIGNIN_SECURE_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        segment.track.loggedIn(this.state);
        if (res.status) {
          this.setState({ isMailSent: true });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err?.response?.status === 503) {
          err.response.json().then((res) => this.props.serviceDownPopup(res));
          return false;
        }
        toast.error(get(err, "message"));
      });
  };

  submitLogin = (values) => {
    const payload = {
      emailOrPhone: values.email,
      password: values.password,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${SIGNIN_API}`;
    request(requestURL, data)
      .then((res) => {
        if (res.status) {
          segment.track.loggedIn(this.state);
          if (get(res, "data.userId")) {
            segment.identify(get(res, "data.userId",{},{
              file: 'FFLogins',
              method: 'submitLogin',
            }));
          }
          if (res.data && res.data.isActive === false) {
            this.props.updateSecureLogin();
            this.setState({ loading: false });
            return;
          }
          StorageService.clear();
          this.loginUser(res.data);
          return true;
        }
        toast.error(get(res, "message"));
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err?.response?.status === 503) {
          err.response.json().then((res) => this.props.serviceDownPopup(res));
          return false;
        }
        toast.error(get(err, "message"));
      });
  };

  loginUser = (data) => {
    const redirectToken = base.encode(
      `?token=${data.artistAccessToken}&timestamp=${Date.now()}`
    );
    window.location.replace(`${data.landingPageUrl}${LOGIN}/${redirectToken}`);
  };

  callBack = () => {
    this.props.goToLogin();
  };

  render () {
    const { title, btnText, isSecureLogin } = this.props;
    const { updatedFormData, isMailSent, loading } = this.state;
    if (isMailSent && !loading) {
      return (
        <div className={styles.widgetBox}>
          <div className={styles.content}>
            <div className={styles.icon}>
              <SvgIcons icon={EMAIL_ICON} />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>Check your email!</div>
            </div>
            <div className={styles.subtitle1}>
              We’ve sent a secure login link to <br />
              <span>{updatedFormData?.email}</span>.
            </div>
            <div className={styles.subtitle2}>
              Didn’t recieve an email?{" "}
              <span onClick={() => this.handleSubmit(updatedFormData)}>
                Send Again
              </span>
              .
            </div>
            <div className={`button2 ${styles.btn} ${styles.sendAgainBtn}`}>
              <Button2 link full href={ARTIST_SEARCH_ESTIMATES}>
                OK
              </Button2>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.widgetBox}>
        <div
          className={`${styles.content} ${isSecureLogin && styles.secureLoginDiv
            }`}
        >
          {loading ? (
            <div className={styles.spinDiv}>
              <FontAwesomeIcon icon={faCircleNotch} className="spin" />
            </div>
          ) : (<>
            {
              !(get(this.state, "instanceData.maintenanceMode.isUnderMaintenance") &&
                get(this.state, "instanceData.maintenanceMode.widget.status")) ?
                (<>
                  <div className={styles.headerDiv}>
                    <div className={styles.icon}>
                      <LOGIN_ICON/>
                    </div>
                    <div className={styles.subHeaderDiv}>
                      <h1 className={styles.title}>Welcome Back!</h1>
                      <p className={styles.subtitle}>{title}</p>
                    </div>
                  </div>
                  <div className={styles.loginContent}>
                    <Formik
                      initialValues={updatedFormData}
                      validationSchema={
                        isSecureLogin
                          ? this.validationSchemaEmail
                          : this.validationLoginSchema
                      }
                      onSubmit={this.handleSubmit}
                    >
                      <Form className={styles.loginFormContainer}>
                        <div
                          className={`form-group mb-0 ${styles.fieldContainer}`}
                        >
                          <FormField
                            name="email"
                            placeholder="Email address"
                            type="text"
                            label="Email address"
                            className={styles.inputField}
                            errorStyle={styles.errorClass}
                          />
                        </div>
                        {!isSecureLogin && (
                          <div
                            className={`form-group mb-0 ${styles.forgotPasswordDiv}`}
                          >
                            <FormField
                              name="password"
                              placeholder="Password"
                              as="password"
                              label="Password"
                              className={styles.inputField}
                              errorStyle={styles.errorClass}
                            />
                            <div
                              className={styles.forgotPasswordLink}
                              onClick={this.props.goToResetPassword}
                            >
                              Forgot password?
                            </div>
                          </div>
                        )}
                        <div
                          className={`${styles.btn} ${isSecureLogin && styles.secureLoginStyle
                            }`}
                        >
                          <Button2 type="submit">{btnText}</Button2>
                          <div className={styles.signInContainer}>
                            <p>Don't have an account?</p>
                            <Link to={FUNDING_NETWORK}><p>Sign Up</p></Link>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                    {/* TODO Uncomment when need to enable secure link login */}
                    {/* <div className={styles.redirectToSecureLoginDiv}>
                  <div>OR</div>
                  <div
                    onClick={
                      !isSecureLogin ? this.props.goToSecureLink : this.callBack
                    }
                    className={styles.link}
                  >
                    {!isSecureLogin ? "Login via secure link" : "Login"}
                  </div>
                </div> */}
                  </div>
                </>) : (
                  <div className={styles.subHeaderDiv}>
                    <h1 className={styles.title}>
                      {get(
                        this.state,
                        "instanceData.maintenanceMode.widget.headerText",
                        "Site Maintenance",
                      )}
                    </h1>
                    <p className={styles.subtitle}>
                      {get(
                        this.state,
                        "instanceData.maintenanceMode.widget.detailText",
                        "We are currently going through some site maintenance. Please come back within 24 hours.",
                      )}
                    </p>
                  </div>
                )}
          </>
          )}
        </div>
      </div>
    );
  }
}
export default FFLogins;
