import StorageService from "./StorageService";
import axios from "axios";

const STORAGE_KEY = "jwtToken";
const CUSTOM_KEY = "customToken";

const AuthTokenService = {
  exists() {
    return StorageService.exists(STORAGE_KEY);
  },

  init(token) {
    if (!this.exists()) {
      this.storeToken(token);
    }
    this.applyTokenHeaders();
    return true;
  },

  applyTokenHeaders() {
    if (this.exists()) {
      axios.defaults.headers.common.Authorization = `Bearer ${this.get()}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  },

  storeToken(token) {
    StorageService.set(STORAGE_KEY, token, {
      hash: true,
    });
    this.init();
  },

  get() {
    return StorageService.get(STORAGE_KEY);
  },

  clear() {
    // Clearing the whole localstore on logout should be moved
    // into it's own auth status/action handler service
    StorageService.delete(CUSTOM_KEY);
    return StorageService.delete(STORAGE_KEY);
  },
};

export default AuthTokenService;
