import React from "react";
import { ReactComponent as TikTokLogo } from "../../assets/SVGs/socialIcons/tiktok.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/SVGs/socialIcons/linkedin.svg";
import { ReactComponent as FacebookLogo } from "../../assets/SVGs/socialIcons/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../assets/SVGs/socialIcons/instagram.svg";
import { ReactComponent as YoutubeLogo } from "../../assets/SVGs/socialIcons/youtube.svg";
import { ReactComponent as SpotifyLogo } from "../../assets/SVGs/socialIcons/spotify.svg";
import { ReactComponent as TwitterLogo } from "../../assets/SVGs/socialIcons/twitter.svg";
import {
  ARTIST_SEARCH_ESTIMATES,
  ARTIST_STORIES,
  LABELHOME,
  PARTNERS,
  FUNDING_NETWORK,
  WHY,
} from "../../routes/constants";

export const SOCIAL_LINKS = [
  {
    name: "TikTok",
    icon: <TikTokLogo />,
    to: "https://www.tiktok.com/@beatbread",
  },
  {
    name: "LinkedIn",
    icon: <LinkedInLogo />,
    to: "https://www.linkedin.com/company/beatbread",
  },
  {
    name: "Facebook",
    icon: <FacebookLogo />,
    to: "https://m.facebook.com/beatbread",
  },
  {
    name: "Instagram",
    icon: <InstagramLogo />,
    to: "http://instagram.com/beatbread",
  },
  {
    name: "Twitter",
    icon: <TwitterLogo />,
    to: "https://twitter.com/beatBreadfunds",
  },
  {
    name: "Youtube",
    icon: <YoutubeLogo />,
    to: "https://www.youtube.com/@beatbread",
  },
  {
    name: "Spotify",
    icon: <SpotifyLogo />,
    to: "https://open.spotify.com/user/31eduvhrwktjdtatwd2cgfv7m7em?si=07411744f34d49b2",
  },
];

export const MENU_OPTIONS = [
  {
    title: "Funding Network",
    link: FUNDING_NETWORK,
  },
  {
    title: "Artist Funding",
    link: ARTIST_SEARCH_ESTIMATES,
    options: [
      { title: "How It Works", link: WHY },
      { title: "Testimonials", link: ARTIST_STORIES },
    ],
  },
  {
    title: "Label Funding",
    link: LABELHOME,
  },
  {
    title: "Partners",
    link: PARTNERS,
  },
];
