import React, { Component } from "react";
import "./Navbar.scss";
import Scroller from "../Scroller";
import { Link } from "react-router-dom";
import {
  HOME,
  LOGIN,
  PAGE_DATA,
  AGENT_REGEX,
  ARTICLE_REGEX,
  KNOWLEDGECENTER,
  RELEASE_REGEX,
  PRESS,
  TESTIMONIAL_REGEX,
  ARTIST_STORIES,
} from "../../routes/constants";
import { A, Dropdowns } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { MOBILE_BREAK } from "../../constants/constants";
import { ReactComponent as BeatBreadLogo } from "../../assets/SVGs/logos/beatBread-logo.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ReactComponent as Arrow } from "../../assets/SVGs/navbar-arrow-2.svg";

function NavMobileMenu(props) {
  return (
    <div className="nav-mobile-menu">
      <div className="nav-mobile-menu-container">
        <div className="nav-mobile-left">
          <div className="nav-links-mobile">
            {props.pageData?.navLinks?.map((e, i) => (
              <div
                key={i}
                className="nav-link"
                onClick={() => props.toggle(false)}
              >
                {e.to ? (
                  e.to.slice(0, 1) === "#" ? (
                    <Scroller
                      scrollID={e.to}
                      href={props.home}
                      className="nl-title"
                    >
                      {e.title}
                    </Scroller>
                  ) : (
                    <A
                      to={e.to}
                      className="nl-title"
                      onClick={() => props.toggle()}
                    >
                      {e.title}
                    </A>
                  )
                ) : (
                  <div className="nl-title">{e.title}</div>
                )}
                {e.links.length > 0 && (
                  <div className="nl-list">
                    {e.links.map((ee, ii) => (
                      <React.Fragment key={ii}>
                        {ee?.to?.slice(0, 1) === "#" ? (
                          <div onClick={() => props.toggle(false)}>
                            <Scroller
                              scrollID={ee.to}
                              href={props.home}
                              className="nl-link"
                            >
                              {ee.name}
                            </Scroller>
                          </div>
                        ) : (
                          <>
                            {ee.links?.length ? (
                              <>
                                <div className="subMenu">
                                  <Accordion expanded>
                                    <AccordionSummary
                                      expandIcon={<Arrow />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>Resources</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {ee.links?.map((eee) => (
                                        <A
                                          to={eee.to}
                                          className="nl-link subLinks"
                                        >
                                          {eee.name}
                                        </A>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </>
                            ) : (
                              <A
                                to={ee.to}
                                className="nl-link"
                                onClick={() => props.toggle()}
                              >
                                {ee.name}
                              </A>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {e.links.length === 0 && <></>}
              </div>
            )) || <></>}
            {props.pageData?.loginButton?.show ? (
              <>
                <div className="nav-divider-mobile"></div>
                <div className="nav-link nav-link-login">
                  <A a to={`${props.basePath}${LOGIN}`} className="nav-link-a">
                    {props.pageData?.loginButton?.text || "Login"}
                  </A>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="nav-mobile-right"></div>
      </div>
    </div>
  );
}

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
      mobile: false,
      envPath: "",
      pageData: {},
    };
    this.navRef = React.createRef();
  }

  toggleMenu = (override) => {
    this.setState({ showMenu: override || !this.state.showMenu });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.envPath !== process.env.REACT_APP_URL) {
      this.setState({ envPath: process.env.REACT_APP_URL });
    }
    let currentLocation;

    if (AGENT_REGEX.test(this.props.currentLocation)) {
      currentLocation = HOME;
    } else if (ARTICLE_REGEX.test(this.props.currentLocation)) {
      currentLocation = KNOWLEDGECENTER;
    } else if (RELEASE_REGEX.test(this.props.currentLocation)) {
      currentLocation = PRESS;
    } else if (TESTIMONIAL_REGEX.test(this.props.currentLocation)) {
      currentLocation = ARTIST_STORIES;
    } else {
      currentLocation = this.props.currentLocation;
    }

    if (prevState.pageData !== PAGE_DATA[currentLocation]) {
      this.setState({ pageData: PAGE_DATA[currentLocation] });
    }
  }

  componentDidMount() {
    if (this.navRef.current.clientWidth <= MOBILE_BREAK) {
      this.setState({ mobile: true });
    }
    this.setState({ envPath: process.env.REACT_APP_URL });
  }

  render() {
    const navMobileMenuHeaderStyles = {
      ...this.props.navMobileMenuHeaderStyle,
      ...(this.props.mobileHeadingBackground
        ? { background: this.props.mobileHeadingBackground }
        : {}),
    };
    return (
      <>
        {this.state.showMenu ? (
          <NavMobileMenu
            toggle={this.toggleMenu}
            basePath={this.state.envPath}
            pageData={this.state.pageData}
            currentLocation={
              AGENT_REGEX.test(this.props.currentLocation)
                ? HOME
                : this.props.currentLocation
            }
            home={this.props.home}
          />
        ) : (
          <></>
        )}
        <div
          ref={this.navRef}
          className={`navbar ${
            this.props.pages ? "navbar-pages" : "navbar-home"
          }`}
        >
          <div className="navbar-left">
            <div className="navbar-logo">
              <Link to={this.props.home} className="nav-link-a">
                <BeatBreadLogo />
              </Link>
            </div>
          </div>
          <div className="navbar-right">
            <div className="navbar-links">
              <Dropdowns
                home={this.props.home}
                data={this.state.pageData?.navLinks}
              />
            </div>
            {this.state.pageData?.loginButton?.show ? (
              <a
                href={`${this.state.envPath}${LOGIN}`}
                className="navbar-login-button"
              >
                <div>{this.state.pageData.loginButton.text}</div>
              </a>
            ) : (
              <div className="navbar-no-login"></div>
            )}
          </div>
        </div>
        <div
          className={`nav-mobile-menu-header ${
            this.props.pages ? "nav-mobile-menu-header-pages" : ""
          }`}
          style={navMobileMenuHeaderStyles}
        >
          <Link to={this.props.home} className="nav-mobile-logo">
            <BeatBreadLogo />
          </Link>
          <div
            className="nav-mobile-menu-button"
            style={
              this.state.showMenu ? { position: "fixed", right: "40px" } : {}
            }
            onClick={() => this.toggleMenu()}
          >
            {this.state.showMenu ? (
              <FontAwesomeIcon
                icon={faXmark}
                style={{ color: "#FFFFFF", height: "100%", width: "100%" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                style={{ color: "#FFFFFF", height: "100%", width: "100%" }}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Navbar;
