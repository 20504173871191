import React, { Component } from "react";
import "./GlossaryBox.scss";
import { Transition } from "react-transition-group";
import { ReactComponent as BoxClosed } from "../../assets/SVGs/plus.svg";
import { ReactComponent as BoxOpened } from "../../assets/SVGs/minus.svg";
import { ReactComponent as PlayIcon } from "../../assets/SVGs/play-icon.svg";
import { ReactComponent as DoubleArrowSvg } from "../../assets/SVGs/arrows-two.svg";

const duration = 0.25;

const defaultStyle = {
  opacity: 0,
  maxHeight: "0px",
  padding: "0px 30px 0px 30px",
};

const transitionStyles = {
  entering: { opacity: 1, padding: "0 0px 20px 0px", maxHeight: "1000px" },
  entered: { opacity: 1, padding: "0 0px 20px 0px", maxHeight: "1000px" },
  exiting: { opacity: 0, padding: "0px 30px 0px 30px", maxHeight: "0px" },
  exited: { opacity: 0, padding: "0px 30px 0px 30px", maxHeight: "0px" },
};

class GlossaryBox extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  toggleBox = () => {
    this.setState({ open: !this.state.open });
  };

  renderLinkDiv = () => (
    <a className="linkDiv" href={this.props.link} rel="noreferrer">read more <DoubleArrowSvg /></a>
  );

  render() {
    return (
      <>
        <div className="gloss-box" onClick={this.toggleBox}>
          <div ref={this.headerRef} className="gb-header">
            <div
              className={`gb-term ${this.state.open ? "gb-open" : "gb-closed"}`}
            >
              <span>{this.props.term}</span>
              {this.props.video && (
                <div
                  onClick={() => this.props.handleModal(this.props.video)}
                  className="icon-div"
                >
                  <PlayIcon />
                </div>
              )}
            </div>
            <div className="gb-arrow">
              {this.state.open ? <BoxOpened /> : <BoxClosed />}
            </div>
          </div>
          <Transition in={this.state.open} timeout={duration}>
            {(tState) => (
              <div
                ref={this.definitionRef}
                className={`gb-definition`}
                style={{
                  ...defaultStyle,
                  ...transitionStyles[tState],
                }}
              >
                {this.props.children || this.props.definition}
                {this.props.link && this.renderLinkDiv()}
              </div>
            )}
          </Transition>
        </div>
      </>
    );
  }
}

export default GlossaryBox;
