import React, { Component } from "react";
import "./PagesCard.scss";

class PagesCard extends Component {
	constructor() {
		super();
		this.state = {
			cardHeight: '100%',
		}
	}


	render() {
		return (
			<div className="pages-card">
				<div className="pages-card-container">
					<div className="pages-card" style={this.props.style || {}}>
						{this.props.children}
					</div>
				</div>
			</div>
		)
	}
}

export default PagesCard;