import React, { Component } from "react";
import "./Train.scss";
import Carousel from "../Carousel";
import SASS from "../../sass/_exports.scss";

function Platforms(props) {
  return (
    <>
      {props.preLoad || props.transition ? (
        <>
          {props.platforms.length && (
            <Rail
              preLoad
              style={props.style || {}}
              activePlatform={props.activePlatform}
            >
              {props.platforms}
            </Rail>
          )}
        </>
      ) : (
        <Rail>{props.platforms[props.activePlatform]}</Rail>
      )}
    </>
  );
}

function Rail(props) {
  return (
    <Carousel
      settings={{
        vertical: props.vertical || false,
        // slidesToShow: 4,
        // slidesToScroll: 1,
        responsive: [
          {
            breakpoint: parseInt(SASS.XS_N),
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(SASS.SM_N),
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(SASS.MD_N),
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(SASS.LG_N),
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(SASS.XL_N),
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(SASS.XXL_N),
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(SASS.XXL_XK_N),
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(SASS.XK_N),
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: parseInt(3850),
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            },
          },
        ],
      }}
    >
      {props.children.map((e, i) => (
        <div key={i} className="train-car">
          {e}
        </div>
      ))}
    </Carousel>
  );
}

class Train extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePlatform: 0,
      upPlatform: 0,
      downPlatform: 0,
      platformTotal: 1,
      changeDirection: "current",
      platforms: props.children,
    };
  }

  render() {
    return (
      <>
        <div className="train">
          {this.state.platforms.length > 0 ? (
            <Platforms
              platforms={this.state.platforms}
              activePlatform={this.state.activePlatform}
              preLoad={this.props.preLoad}
              transition={this.props.transition}
              changeDirection={this.state.changeDirection}
            />
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

export default Train;
