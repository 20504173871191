import React, { Component } from "react";
import "./Carousel.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as LeftArrow } from "../../assets/SVGs/icon_arrow_left.svg";
import { ReactComponent as RightArrow } from "../../assets/SVGs/icon_arrow_right.svg";

function CustomNextArrow(props) {
  return (
    <div className="carousel-controls">
      <div className="carousel-forward carousel-arrow" onClick={props.onClick}>
        <RightArrow />
      </div>
    </div>
  );
}

function CustomPreviousArrow(props) {
  return (
    <div className="carousel-controls">
      <div className="carousel-back carousel-arrow" onClick={props.onClick}>
        <LeftArrow />
      </div>
    </div>
  );
}

class Carousel extends Component {
  constructor() {
    super();
    this.state = {
      carouselSettings: {
        dots: false,
        infinite: true,
        speed: 500,
        // slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 7000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPreviousArrow />,
      },
    };
  }

  render() {
    const styles = {
      ...(this.props.style || {}),
      position: "relative",
      maxWidth: "calc(100vw - 40px)",
      width: "100%",
      height: "auto",
    };
    return (
      <div className="carousel">
        <Slider
          dots={this.props.dots || false}
          infinite={this.props.infinite || true}
          speed={this.props.speed || 500}
          slidesToShow={this.props.slidesToShow || 1}
          slidesToScroll={this.props.slidesToScroll || 1}
          autoplaySpeed={this.props.autoplaySpeed || 7000}
          adaptiveHeight={this.props.adaptiveHeight || true}
          {...(!this.props.noArrow
            ? {
                nextArrow: <CustomNextArrow />,
                prevArrow: <CustomPreviousArrow />,
              }
            : {})}
          {...(this.props.settings || {})}
          style={styles}
        >
          {this.props.children || <></>}
        </Slider>
      </div>
    );
  }
}

export default Carousel;
