export const VISIBILITY = "Visibility";
export const VISIBILITY_OFF = "VisibilityOff";
export const PROFILE_ICON = "AccountCircle";
export const LEFT_ARROW_ICON = "LeftArrow";
export const BB_LOGO = "BeatBreadLogo";
export const TOO_BIG_GRAPH = "TooBigGraph";
export const TOO_SMALL = "TooSmall";
export const LOGIN_ICON = "LoginIcon";
export const EMAIL_ICON = "EmailIcon";
export const REST_PASSWORD = "ResetIcon";
export const BB_ICON = "BBIcon";
