const selectStyle = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    maxWidth: 300,
    textAlign: "left",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `#5d5d5d`,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 2,
    padding: "3px 0",
    border: "none",
    boxShadow: "unset",
    borderBottom: `1px solid #7d7d7d`,
    height: 50,
    "&:hover": {
      borderBottom: `1px solid #5d5d5d`,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 2,
    maxHeight: 200,
    zIndex: 5,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
  }),
};

export const formStyle = {
  ...selectStyle,
  control: (provided) => ({
    ...provided,
    ...selectStyle.control(),
    borderBottom: "transparent",
    "&:hover": {
      borderBottom: "transparent",
    },
    backgroundColor: "unset",
  }),
  container: (provided) => ({
    ...provided,
    maxWidth: "100%",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#5849a6",
    fontFamily: "Poppins, sans-serif",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#5849a6",
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    opacity: 0.3,
  }),
};

export const selectFormStyle = {
  ...selectStyle,
  control: (provided) => ({
    ...provided,
    ...selectStyle.control(),
    borderBottom: `1px solid #000000`,
    "&:hover": {
      borderBottom: `1px solid #000000`,
    },
    height: 20,
  }),
  container: (provided) => ({
    ...provided,
    maxWidth: "100%",
    color: "#000000",
  }),
  placeholder: (provided) => ({
    ...provided,
    font: `700 14px Poppins, sans-serif`,
    color: "#acacac",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "0px",
    color: "#594aa5",
  }),
  input: (provided) => ({
    ...provided,
    color: "#594aa5",
    font: `700 14px Poppins, sans-serif`,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "594aa5",
    font: `700 14px Poppins, sans-serif`,
  }),
};

export default selectStyle;
