import React, { Component } from "react";
import styles from "../ContactUsForm.module.scss";
import { ReactComponent as ThanksIcon } from "../../../assets/SVGs/success-bubble.svg";
import Button2 from "../../Button2";
import { Link } from "react-router-dom";

class FormSuccess extends Component {
  constructor() {
    super();
    this.state = {
      clicked: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      clicked: true,
    });
  }

  render() {
    return (
      <div className={styles.contactUsForm}>
        <div className={styles.successIcon}>
            <ThanksIcon />
        </div>
        <div className={styles.successText}>
            <div className={styles.title}>Thank You!</div>
            <div className={styles.subtitle}>
            We are reviewing the information that you provided. We'll be in
            touch in the next few days.
            </div>
        </div>
        <Button2 className={styles.button2} full>
          <Link to="/" className={styles.homeLink}>
            RETURN TO HOMEPAGE »
          </Link>
        </Button2>
      </div>
    );
  }
}

export default FormSuccess;
