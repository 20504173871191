export const EMAIL_REGEX =
  /^[A-Za-z0-9_+#*&?$!.-]+@[A-Za-z0-9_+#*&?$!.-]+\.[A-Za-z]{2,}$/;

export const WEBSITE_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;

export const STAGES = {
  INITIAL: "initial",
  SUCCESS: "success",
  ERROR: "error",
};

export const initialBoxValues = {
  "$25k-$50k": false,
  "$50k-$100k": false,
  "$100k-$250k": false,
  "$250k-$500k": false,
  "$500k-$1M": false,
  "Over $1M": false,
};

export const HEARD_FROM = ['Instagram', 'Facebook', 'LinkedIn', 'Google', 'beatBread Emailed Me', 'Industry News Outlet', 'Referred by Someone']
