import React, { Component } from "react";
import { NumericFormat } from "react-number-format";
import styles from "./FormField.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";
import { ErrorMessage, Field } from "formik";
import { VISIBILITY, VISIBILITY_OFF } from "../MaterialIcons/constants";
import SvgIcons from "../MaterialIcons/SvgIcons";
import { get, find } from "lodash";
import { DECIMAL_COUNT } from "./constant";
import Select from "react-select";
import { selectFormStyle } from "./style";

class FormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconPass: VISIBILITY,
      passType: "password",
      labelActive: false,
    };
  }

  changeInputType = () => {
    this.state.passType === "password"
      ? this.setState({ passType: "text", iconPass: VISIBILITY_OFF })
      : this.setState({ passType: "password", iconPass: VISIBILITY });
  };

  focusChange = (e) => {
    if (get(e, "type") === "focus") {
      this.setState({ labelActive: true });
    } else if (get(e, "target.value")) {
      this.setState({ labelActive: true });
    } else {
      this.setState({ labelActive: false });
    }
  };

  renderLabelUI = () => {
    const { label, name } = this.props;
    if (label) {
      return <label htmlFor={name}>{label}</label>;
    }
    return <></>;
  };

  getFieldComponent = () => {
    const {
      type,
      name,
      placeholder,
      className,
      style,
      as = "input",
      icon,
      onChangeHandle,
      isBoxInput,
      showErrorWithIcon,
      menuPosition,
      radioValueList,
      selectedCountry,
      handleRadioChange,
      val,
      options = [],
      ...otherProps
    } = this.props;
    switch (as) {
      case "input":
        return (
          <Field
            type={type}
            name={name}
            placeholder={placeholder}
            className={`${styles.boxInput} ${className}`}
            style={style}
            as={as}
            {...otherProps}
          />
        );
      case "radio":
        return (
          <Field
            type={type}
            name={name}
            className={className}
            style={style}
            as={as}
            {...otherProps}
          >
            {({ field }) => (
              <>
                {radioValueList.map((list, index) => {
                  const randomId = Math.floor(Math.random() * 100);
                  return (
                    <div
                      className={styles.radioInput}
                      key={`${list.label}${list.value}-${index}`}
                    >
                      <input
                        {...field}
                        {...otherProps}
                        type={as}
                        className={`${className}`}
                        name={name}
                        id={`${list.label}${list.value}-${randomId}`}
                        value={list.value}
                        checked={field.value === list.value}
                      />

                      <label
                        htmlFor={`${list.label}${list.value}-${randomId}`}
                        className={styles.label}
                      >
                        {list.label}
                      </label>
                    </div>
                  );
                })}
              </>
            )}
          </Field>
        );
      case "phone":
        return (
          <Field name={name} {...otherProps}>
            {({ field, form }) => (
              <>
                <PhoneInput
                  value={field.value || this.props.value}
                  dropdownClass={styles.dropdownClass}
                  containerClass={styles.containerClass}
                  country={`us`}
                  inputClass={styles.inputClass}
                  onChange={(number, country) => {
                    form.setFieldValue(as, number);
                    form.setFieldValue("countryCode", country.dialCode);
                  }}
                  onBlur={(e) => {
                    form.setFieldTouched(as, true);
                  }}
                  inputProps={{
                    className: styles.inputClass,
                    name: field.name,
                  }}
                  placeholder={placeholder}
                  disabled={get(otherProps, "disabled")}
                />
              </>
            )}
          </Field>
        );
      case "password":
        return (
          <Field name={name} {...otherProps}>
            {({ field }) => (
              <div className={`${styles.container} ${styles.password}`}>
                <input
                  type={this.state.passType}
                  className={`${styles.boxInput} ${className}`}
                  style={style}
                  placeholder={placeholder}
                  onFocus={this.focusChange}
                  {...field}
                  onBlur={(e) => {
                    field.onBlur(e);
                    this.focusChange(e);
                  }}
                />
                <i onClick={this.changeInputType} className={styles.iconDiv}>
                  <SvgIcons
                    icon={this.state.iconPass}
                    className={`${styles.icon}`}
                  />
                </i>
              </div>
            )}
          </Field>
        );
      case "checkbox":
        return (
          <Field
            type={type}
            name={name}
            className={className}
            placeholder={placeholder}
            style={style}
            as={as}
            {...otherProps}
          >
            <>
              <input type={as} className={`${className} "mr-3"`} name={name} />
            </>
          </Field>
        );
      case "numeric":
        return (
          <Field name={name} {...otherProps}>
            {({ field, form }) => (
              <>
                <NumericFormat
                  value={field.value ?? this.props.value}
                  name={name}
                  className={`${styles.boxInput} ${className}`}
                  placeholder={placeholder}
                  onBlur={() => {
                    form.setFieldTouched(name, true);
                  }}
                  onValueChange={(val) => {
                    form.setFieldValue(name, val.floatValue ?? "");
                  }}
                  style={style}
                  thousandSeparator
                  valueIsNumericString
                  decimalScale={DECIMAL_COUNT}
                  {...otherProps}
                />
              </>
            )}
          </Field>
        );
      case "select":
        return (
          <Field name={name} {...otherProps} data-testid="selectField">
            {({ field, form }) => (
              <Select
                options={options}
                placeholder={placeholder}
                value={find(options, {
                  value: field.value || this.props.value,
                })}
                onChange={(e) => {
                  form.setFieldValue(name, e.value);
                }}
                styles={selectFormStyle}
                isDisabled={otherProps.disabled}
                menuPosition={menuPosition && "fixed"}
                {...otherProps}
              />
            )}
          </Field>
        );

      default:
        return null;
    }
  };

  renderIcon = () =>
    this.props.icon ? (
      <span className={styles.componentIcon}>{this.props.icon}</span>
    ) : null;

  renderErrorUI = (name, errorStyle) => {
    return (
      <ErrorMessage
        name={name}
        render={(error) =>
          typeof error === "string" && (
            <div className={`${styles.errorClass} ${errorStyle}`}>{error}</div>
          )
        }
      />
    );
  };

  render() {
    const { name, mainDiv, errorStyle } = this.props;

    return (
      <div
        className={`${styles.formContainer} ${
          styles.fieldContainer
        } ${mainDiv} ${this.props.icon ? styles.inputWithIcon : ""}`}
      >
        {this.renderLabelUI()}
        {this.renderIcon()}
        {this.getFieldComponent()}
        {this.renderErrorUI(name, errorStyle)}
      </div>
    );
  }
}

export default FormField;
