import React, { useState } from "react";
import styles from "./MajorManagers.module.scss";
import { Q1, Q2, Q3, Form, WrappedWidget } from "./questions";


const DECISION_TREE = {
  q1: {
    yes: "q2",
    no: "widget",
  },
  q2: {
    yes: "form",
    no: "q3",
  },
  q3: {
    yes: "",
    no: "widget",
  },
};

const saveCard = (card) => {
  sessionStorage.setItem("mm_card",card);
}

const checkCard = (fallback="q1") => {
  return sessionStorage.getItem("mm_card") || fallback;
}

function MajorManagers() {
  const [question, setQuestion] = useState(checkCard());

  const handleReturn = (answer) => {
    const newQuestion = DECISION_TREE[question][answer];
    setQuestion(newQuestion);
    saveCard(newQuestion);
  };

  const handleBack = (override) => {
    const backTo = override || "q1"
    setQuestion(backTo);
    saveCard(backTo)
  };

  return (
    <div className={styles.majorManagers}>
      {question === "q1" ? <Q1 return={handleReturn} /> : <></>}
      {question === "q2" ? (
        <Q2 return={handleReturn} back={handleBack} />
      ) : (
        <></>
      )}
      {question === "q3" ? (
        <Q3 return={handleReturn} back={handleBack} />
      ) : (
        <></>
      )}
      {question === "form" ? (
        <Form return={handleReturn} back={handleBack} />
      ) : (
        <></>
      )}
      {question === "widget" ? (
        <WrappedWidget return={handleReturn} back={handleBack} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default MajorManagers;
