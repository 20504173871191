import React, { Component, createRef } from "react";
import mainStyles from "./SearchBar.module.scss";
import ReactSelect from "react-select/async";
import "./index.scss";
import {
  API_URL,
  ARTIST_API,
  ARTIST_ENROLLMENT,
  AUTH,
  FUNDING_OPTION,
} from "../../routes/constants";
import {
  SingleValue,
  NoOptionsMessage,
  LoadingMessage,
  getOptions,
  handleChange,
  getPlaceHolderIcon,
  getPlaceHolderIconInput,
  clearSelectedArtistDetails,
  getInstance,
  handleRemoveArtist,
} from "./helper";
import { Button2 } from "../../components";
import { SEARCH_OPTIONS } from "./constants";
import { debounce, get } from "lodash";
import autofillStyle from "./AutofillStyle";
import searchStyle from "./searchStyle";
import request from "../../utils/request";
import { toast } from "react-toastify";
import AuthTokenService from "../../utils/AuthTokenService";
import { Link } from "react-router-dom";
import { ReactComponent as SwapIcon } from "../../assets/SVGs/swap-icon.svg";
import segment from "../../utils/segment";

import SvgIcons from "../../components/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../components/MaterialIcons/constants";
import { withRouter } from "../../routes/withRouter";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      landingUrl: "",
      searchType: "name",
      id: "",
      name: "",
      image: "",
      artistId: "",
      selectOptions: [],
      spotifyId: false,
      total: 0,
      loader: true,
    };
    this.getOptions = debounce(getOptions.bind(this), 600);
    this.searchBarRef = createRef();
  }

  componentDidMount() {
    getInstance.call(this);
  }

  formatOptionLabel = ({ label, imageUrl }) => (
    <div className={mainStyles.selectOption}>
      <div className={mainStyles.profile}>
        {imageUrl ? (
          <img src={imageUrl} alt="artist_profile" />
        ) : (
          <SvgIcons className={mainStyles.profileIcon} icon={PROFILE_ICON} />
        )}
      </div>
      <p>{label}</p>
      <Button2 light className={`${mainStyles.btn} ${!this.state.id}`}>Select</Button2>
    </div>
  );

  handleSelectChange = () => {
    this.setState(
      (prevState) => ({ spotifyId: !prevState.spotifyId }),
      () => {
        if (this.state.spotifyId) {
          this.setState({ searchType: "id" });
        } else {
          this.setState({ searchType: "name" });
        }
      }
    );
  };

  handleStateChange = (event) => {
    console.log("event: ", event);
    this.setState({ id: event.value, name: event.label, image: event.imageUrl })

    // Call the callback function from the parent component
    this.props.onUpdateArtist(event);
  };

  renderSearchBar = () => (
    <>{this.props.standalone ? (
        <div className={`${mainStyles.inputs}`}>
            <div className={`${mainStyles.search}`}>
                <div className={`${mainStyles.label}`}>{this.state.spotifyId
                            ? SEARCH_OPTIONS[1].label
                            : SEARCH_OPTIONS[0].label}</div>
                <ReactSelect
                    maxMenuHeight={176}
                    id="artistSelect"
                    classNamePrefix="select-search"
                    isClearable={false}
                    loadOptions={(inputValue, callback) =>
                    this.getOptions.call(this, inputValue, callback)
                    }
                    onChange={this.handleStateChange}
                    formatOptionLabel={this.formatOptionLabel}
                    name="identifier"
                    ref={this.searchBarRef}
                    components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    SingleValue: (prop) => SingleValue(prop, (props)=> handleRemoveArtist.call(this,props)),
                    NoOptionsMessage,
                    LoadingMessage,
                    }}
                    isDisabled={this.state.loading}
                    styles={searchStyle}
                    placeholder={getPlaceHolderIconInput(this.state.spotifyId)}
                />
                <div
                className={mainStyles.switchDiv}
                onClick={this.handleSelectChange}
                >
                    Use{" "}
                    {this.state.spotifyId
                        ? SEARCH_OPTIONS[0].label
                        : SEARCH_OPTIONS[1].label}{" "}
                    Instead
                </div>
            </div>
        </div>
    ) : (
        <>{this.props.input ? (
            <div className={`${mainStyles.inputs}`}>
                <div className={`${mainStyles.search}`}>
                    <div className={`${mainStyles.label}`}>{this.state.spotifyId
                            ? SEARCH_OPTIONS[1].label
                            : SEARCH_OPTIONS[0].label}</div>
                    <ReactSelect
                        maxMenuHeight={176}
                        id="artistSelect"
                        classNamePrefix="select-search"
                        isClearable={false}
                        loadOptions={(inputValue, callback) =>
                        this.getOptions.call(this, inputValue, callback)
                        }
                        onChange={(e) => handleChange.call(this, e)}
                        formatOptionLabel={this.formatOptionLabel}
                        name="identifier"
                        ref={this.searchBarRef}
                        components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        SingleValue: (prop) => SingleValue(prop, null, this.state.loading),
                        NoOptionsMessage,
                        LoadingMessage,
                        }}
                        isDisabled={this.state.loading}
                        styles={searchStyle}
                        placeholder={getPlaceHolderIconInput(this.state.spotifyId)}
                    />
                    <div
                    className={mainStyles.switchDiv}
                    onClick={this.handleSelectChange}
                    >
                        Use{" "}
                        {this.state.spotifyId
                            ? SEARCH_OPTIONS[0].label
                            : SEARCH_OPTIONS[1].label}{" "}
                        Instead
                    </div>
                </div>
            </div>
        ) : (
            <div className={`${mainStyles.searchBarDiv}`}>
                <div className={`${mainStyles.searchBarInnerBar}`}>
                    <div className={`${mainStyles.spotifySearch}`}>
                    <div className={mainStyles.idDiv} onClick={this.handleSelectChange}>
                        <span>
                        USE&nbsp;
                        {this.state.spotifyId
                            ? SEARCH_OPTIONS[0].label
                            : SEARCH_OPTIONS[1].label}
                        </span>
                        <SwapIcon />
                    </div>
                    <ReactSelect
                        maxMenuHeight={176}
                        id="artistSelect"
                        classNamePrefix="select-search"
                        isClearable={false}
                        loadOptions={(inputValue, callback) =>
                        this.getOptions.call(this, inputValue, callback)
                        }
                        onChange={(e) => handleChange.call(this, e)}
                        formatOptionLabel={this.formatOptionLabel}
                        name="identifier"
                        ref={this.searchBarRef}
                        components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        SingleValue: (prop) => SingleValue(prop, null, this.state.loading),
                        NoOptionsMessage,
                        LoadingMessage,
                        }}
                        isDisabled={this.state.loading}
                        styles={autofillStyle}
                        placeholder={getPlaceHolderIcon(this.state.spotifyId)}
                    />
                    </div>
                </div>
            </div>
        )}
        </>
    )}
    </>
  );

    handleSeeFundingOption = ({ id, name }) => {
        this.setState({ loading: true });
        segment.track.usedWidget({ ...this.state, name: name });
        const payload = {
        spotifyId: id,
        agentCode: get(this.props, "params.agent_id"),
        };
        const data = {
        method: "POST",
        body: payload,
        };
        const requestURL = `${API_URL}${AUTH}${ARTIST_API}${FUNDING_OPTION}`;
        request(requestURL, data)
        .then((res) => {
            if (res.status) {
            clearSelectedArtistDetails();
            AuthTokenService.clear();
            window.location.assign(
                `${this.state.landingUrl}/signup/${get(
                res,
                "data.spotify_id",
                ""
                )}/${get(res, "data.request_id", "")}${
                get(this.props, "params.agent_id")
                    ? `/${get(this.props, "params.agent_id")}`
                    : ""
                }`
            );
            return true;
            }
            this.setState({ loading: false });
            toast.error(res?.message);
        })
        .catch((err) => {
            this.setState({ loading: false });
            document.querySelector(
            "#artistSelect .select-search__input-container"
            ).style.display = "inherit";
            document.getElementById("single_value").style.display = "none";
            this.searchBarRef.current.clearValue();
            toast.error(err?.message);
        });
    };

  render() {
    return (
        <div className={mainStyles.searchGroup}>
            {this.props.standalone || this.props.input ? ( null) : (<div className={mainStyles.idDivMobile} onClick={this.handleSelectChange}>
              <span>
                USE&nbsp;
                {this.state.spotifyId
                  ? SEARCH_OPTIONS[0].label
                  : SEARCH_OPTIONS[1].label}
              </span>
              <SwapIcon />
            </div>)}
            {this.renderSearchBar()}
            {this.props.standalone || this.props.input ? ( null) : (
            <div className={mainStyles.loginDiv}>
              I already have an account,{" "}
              <Link to={`${ARTIST_ENROLLMENT}?isLogin=true`}>Log In</Link>
            </div>
            )}
        </div>
    );
  }
}

export default withRouter(SearchBar);