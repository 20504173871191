import React from "react";
import metaJSON from "./constants";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AGENT_HOME, AGENT_REGEX } from "../../routes/constants";
import { get } from "lodash";

function HelmetR(props) {
  const location = useLocation();
  const pathname = location.pathname.match("/.*/$")
    ? location.pathname.replace(/\/$/, "")
    : location.pathname;
  const mJSON = metaJSON[AGENT_REGEX.test(pathname) ? AGENT_HOME : pathname];

  return !props.data ? (
    <Helmet>
      <title>{get(mJSON, "title")}</title>
      <meta name="title" content={get(mJSON, "title")} />
      <meta name="description" content={get(mJSON, "description")} />
    </Helmet>
  ) : (
    <Helmet>
      <title>{get(props.data, "title")}</title>
      <meta name="description" content={get(props.data, "description")} />
      <link rel="canonical" href={get(props.data, "sourceUrl")}></link>
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Article",
          headline: get(props.data, "title"),
          datePublished: get(props.data, "publishDate"),
          author: {
            "@type": "Person",
            name: get(props.data, "author"),
          },
          image: get(props.data, "image"),
        })}
      </script>
    </Helmet>
  );
}

export default HelmetR;
