import React from "react";
import styles from "./Scroller.module.scss";
import { useNavigate, useLocation } from "react-router-dom";


function Scroller(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const cleanID = props.scrollID.slice(0,1) === "#" ? props.scrollID.slice(1) : props.scrollID;


	const handleClick = () => {
		if (props.href) {
			if (location.pathname !== props.href) {
				navigate(`${props.href}${props.scrollID}`);
				
			} else {
				document.getElementById(cleanID).scrollIntoView({behavior: 'smooth'});
			}
		} else {
			document.getElementById(cleanID).scrollIntoView({behavior: 'smooth'});
		}
	}
	

	return (
		<div className={`${styles.scroller} ${props.className || ""}`} style={props.style || {}} onClick={() => handleClick()}>
			{props.children}
		</div>
	)
}

export default Scroller;