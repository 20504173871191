export const cookie = {
  get: () => document.cookie,
  set: (newCookie) => (document.cookie = newCookie),
  parse: (inString) =>
    Object.fromEntries(inString.split(";").map((e) => e.trim().split("="))),
  stringify: (inObject) =>
    Object.entries(inObject)
      .map((e) => e.join("="))
      .join("; "),
};

export const initBanner = () => {
  if (process.env.REACT_APP_CONSENT_BANNER_UUID) {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed.min.js";
    script.async = true;
    script.setAttribute(
      "data-website-uuid",
      process.env.REACT_APP_CONSENT_BANNER_UUID
    );
    script.setAttribute("data-auto-block", "on");
    document.body.appendChild(script);
  }
};
