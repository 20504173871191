import React, { useState, useEffect } from "react";
import styles from "./Toast.module.scss";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import { ReactComponent as BLogo } from "../../assets/SVGs/logos/b-logo.svg";

const duration = 0.25;
const timeout = 200;

const defaultStyle = {
  transition: `right ${duration}s ease-out`,
  right: "-100%",
};

const transitionStyles = {
  entering: { right: "100px" },
  entered: { right: "50px" },
  exiting: { right: "100px" },
  exited: { right: "-100%" },
};

function Toast(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const triggerAlert = () => {
      const delay = props.delay || 5000;
      setTimeout(() => {
        setShow(false);
      }, delay + duration * 1000);
    };
    setShow(props.open);
    triggerAlert();
  }, [props.open, props.delay]);

  const rootDOM = document.getElementById("root");
  return createPortal(
    <Transition in={show} timeout={timeout}>
      {(tState) => (
        <div
          className={styles.toast}
          style={{
            ...defaultStyle,
            ...transitionStyles[tState],
          }}
        >
          <div className={styles.logo}>
            <BLogo />
          </div>
          <div className={styles.content}>
            {props.title ? (
              <div className={styles.title}>{props.title}</div>
            ) : (
              <></>
            )}
            <div className={styles.text}>
              {props.children || props.text || ""}
            </div>
          </div>
        </div>
      )}
    </Transition>,
    rootDOM
  );
}

export default Toast;
