import mainStyles from "./SearchBar.module.scss";
import { API_URL, SPOTIFY_USER_API, USER_API, ADMIN, WHITELABEL_INSTANCE } from "../../routes/constants";
import request from "../../utils/request";
import SvgIcons from "../../components/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../components/MaterialIcons/constants";
import StorageService from "../../utils/StorageService";
import { toast } from "react-toastify";
import { get, invoke } from "lodash";
import { ReactComponent as SearchIcon } from "../../assets/SVGs/search.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export function getOptions(inputValue, callback) {
  if (inputValue.length < 1) {
    callback([]);
  } else {
    const reqData = {
      method: "GET",
    };
    // TODO : Uncomment once spotify issue fixed
    // const requestURL = `${API_URL}${USER_API}${SPOTIFY_USER_API}?q=${inputValue}&type=${this.state.searchType}&limit=5`;
    const requestURL = `https://api.dev.beatbread.com${USER_API}${SPOTIFY_USER_API}?q=${inputValue}&type=${this.state.searchType}&limit=5`;

    request(requestURL, reqData)
      .then((res) => {
        if (!res.status) {
          toast.error(get(res, "message", "Something Went Wrong"));
          callback([]);
        } else {
          const thisref = this;
          callback(getOptionResponse(res, thisref));
        }
      })
      .catch((err) => {
        callback([]);
        toast.error(get(err, "message", "Something Went Wrong"));
      });
  }
}

export const LoadingMessage = (props) => {
  return (
    props.selectProps.inputValue.length && (
      <div className={`${mainStyles.noOption} ${mainStyles.marginRight}`}>
        <span className="spinner-border spinner-border-sm"></span>
      </div>
    )
  );
};

export const NoOptionsMessage = (props) => {
  return (
    props.selectProps.inputValue.length && (
      <div className={mainStyles.noOption}>No data to show</div>
    )
  );
};

export const SingleValue = ({ data, selectProps, ...others }, handleRemove, loading) => {
  return (
    <div id="single_value" className={mainStyles.selectedOption}>
        <div className={mainStyles.profile}>
          {get(data, "imageUrl") ? (
            <img src={data.imageUrl} alt="artist_profile" />
          ) : (
            <SvgIcons icon={PROFILE_ICON} />
          )}
        </div>
        <p className={mainStyles.selectedArtistName}>
          {selectProps.getOptionLabel(data)}{" "}
        </p>
      {loading ? (
        <div className="btnLoader">
          <FontAwesomeIcon icon={faCircleNotch} className={mainStyles.spin} />
        </div>
      ) : (
        <button
          type="button"
          onClick={() => handleRemove(others.clearValue)}
          onTouchEnd={() => handleRemove(others.clearValue)}
          data-testid="removeBtn"
          className={mainStyles.removeBtn}
        >
          Remove
        </button>
      )}
    </div>
  );
};

function getOptionResponse(res, thisref) {
  const data = getSpotifyData(res.data);
  const options = [];
  data.map((item) =>
    options.push({
      value: item.id,
      label: item.name,
      imageUrl: item?.images?.[2]?.url,
    })
  );
  thisref.setState({ selectOptions: options });
  return options;
}

export const getSpotifyData = (data) => data.items || (data.id ? [data] : []);

export function handleChange(e) {
  if (e) {
    document.getElementsByClassName(
      "select-search__input-container"
    )[0].style.display = "none";
    invoke(this, "handleSeeFundingOption", {
      id: e.value,
      name: e.label,
      image: e.imageUrl,
    });
  }
}

export const getLabel = (val) =>
  val === "name" ? "Artist Name" : "Spotify ID";

export const getPlaceHolderIcon = (spotifyId) => (
  <div className={mainStyles.placeHolderIcon}>
    <SearchIcon />
    <span>
      {spotifyId ? "Enter Spotify ID..." : "Begin typing to find an artist..."}
    </span>
  </div>
);
export const getPlaceHolderIconInput = (spotifyId) => (
  <div className={mainStyles.placeHolderIconInput}>
    <span>
      {spotifyId ? "Enter Spotify ID" : "Enter Artist Name"}
    </span>
  </div>
);

export const getArtistDetails = () => {
  return StorageService.get("selectedArtistDetails", {
    localStorage: true,
  });
};

export const clearSelectedArtistDetails = () => {
  StorageService.delete("selectedArtistDetails");
};

export const hexToRgb = (color = "") =>
  color.startsWith("#")
    ? color
        .substring(1)
        .match(/.{2}/g)
        .map((d) => parseInt(d, 16))
        .toString()
    : color;

export function getInstance() {
  const data = {
    method: "GET",
  };
  this.setState({ loading: true });

  const requestURL = `${API_URL}${ADMIN}${WHITELABEL_INSTANCE}`;

  request(requestURL, data)
    .then((res) => {
      this.setState({ loading: false });
      if (res.status) {
        // main color
        invoke(this, "setState", {
          landingUrl: get(res, "data.frontendDomain", ""),
        });
        document.documentElement.style.setProperty(
          "--primary-color",
          get(res.data, "primaryColor", "")
        );
        document.documentElement.style.setProperty(
          "--cta-color",
          get(res.data, "appStyle.ctaColor", "")
        );
        document.documentElement.style.setProperty(
          "--app-primary-background-color",
          get(res.data, "appStyle.primaryBackgroundColor", "")
        );
        document.documentElement.style.setProperty(
          "--help-text-color",
          get(res.data, "contentStyle.helpTextColor", "")
        );

        // rgb colors

        document.documentElement.style.setProperty(
          "--help-text-color-rgb",
          hexToRgb(get(res.data, "contentStyle.helpTextColor", ""))
        );

        document.documentElement.style.setProperty(
          "--cta-color-rgb",
          hexToRgb(get(res.data, "appStyle.ctaColor", ""))
        );

        StorageService.set(
          "appPartner",
          get(res.data, "slugName") === "app" ? "beatBread" : "chordCash"
        );
      }
    })
    .catch((err) => {
      this.setState({ loading: false });
      toast.error(get(err, "message"));
    });
}

export const isMobile = () =>
  /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 768;

export function handleRemoveArtist(clearValue) {
  this.setState({ id: "", name: "", image: "", selectOptions: [] });
  document.querySelector(
    "#artistSelect .select-search__input-container"
  ).style.display = "inherit";

  document.getElementById("single_value").style.display = "none";
  clearValue();
}