import React from "react";
import "./App.scss";
import MainRoutes from "./routes/index";
import "./components/ThirdPartyIntegrations/DataDog";
import { useAgent } from "./hooks";
import Hotjar from "@hotjar/browser";

if (process.env.REACT_APP_HOTJAR_ID) {
  const hotjarVersion = 6;
  Hotjar.init(process.env.REACT_APP_HOTJAR_ID, hotjarVersion);
}

function App() {
  const HOME = useAgent();

  return <MainRoutes home={HOME} />;
}

export default App;
