import React from "react";
import "./Button2.scss";
import variables from "../../mixins.scss";
import { Link } from "react-router-dom";

function Button2(props) {
  const styles = {
    ...(props.style || {
      backgroundColor: props.outline ? `transparent` : props.light ? `${variables.accentColor}` : props.dark ? `${variables.blueVioletColor}` : `${variables.blueVioletColor}`,
      color: props.outline && props.dark ?`${variables.blueVioletColor}` : props.outline && props.light ? `${variables.whiteColor}` : props.light ? `${variables.accentFont}` : props.dark ? `${variables.whiteColor}` : `${variables.whiteColor}`,
      border: props.outline && props.dark ? `1.5px solid ${variables.blueVioletColor}`: props.outline && props.light ? `1.5px solid ${variables.whiteColor}` : `none`,
    }),
    ...(props.full ? { alignSelf: "stretch" } : {}),
  };

  const childStyles = {
    ...(props.childStyle || {
      fill: props.outline && props.dark ?`${variables.blueVioletColor}` : props.outline && props.light ? `${variables.whiteColor}` : props.light ? `${variables.accentFont}` : props.dark ? `${variables.whiteColor}` : `${variables.whiteColor}`,
    }),
  };

  const disabled = props.disabled || false;

  return (
    <>
      {props.link ? (
        <a
          className={`button2 ${props.className}`}
          style={styles}
          href={props.href || "#"}
          alt=""
          disabled={disabled}
        >
          <div>{props.children || props.value || "Submit"}</div>
        </a>
      ) : props.to ? (
        <Link
          type={props.type || "button2"}
          className={`button2 ${props.className}`}
          to={props.to}
          style={styles}
          disabled={disabled}
        >
          <div style={childStyles}>
            {props.children || props.value || "Submit"}
          </div>
        </Link>
      ) : (
        <button
          type={props.type || "button2"}
          className={`button2 ${props.className}`}
          onClick={() => {
            if (typeof props.onClick === "function") {
              props.onClick();
            }
          }}
          style={styles}
          disabled={disabled}
        >
          <div style={childStyles}>
            {props.children || props.value || "Submit"}
          </div>
        </button>
      )}
    </>
  );
}

export default Button2;
