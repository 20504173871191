import { invoke, get } from "lodash";
import {
  API_URL,
  SEGMENT_DATA,
} from "../routes/constants";
import axios from "axios";


const IS_TEST_REGEX = /(\+|beatbread|growexx|chordcash)/ig;
const parseString = (inString) => {
  if (typeof inString === 'string') {
    if (inString === 'null') {
      return null;
    } else if (inString === 'undefined') {
      return undefined;
    } else if (inString === 'NaN') {
      return NaN;
    } else {
      return inString;
    }
  } else {
    return inString;
  }
}
const parseBoolean = (inBool) => {
  if (inBool === 'true') {
    return true;
  } else if (inBool === 'false') {
    return false;
  } else {
    return parseString(inBool);
  }
};
const isTest = (inEmail) => IS_TEST_REGEX.test(inEmail);
const setData = (inEmail,inArtistId,inUserId,inActiveArtist) => {
  if (inUserId) {
    sessionStorage.setItem('bb_segment_user_id',inUserId);
  }
  if (inEmail) {
    sessionStorage.setItem('bb_segment_is_test',isTest(inEmail));
    sessionStorage.setItem('bb_segment_email',inEmail);
  }
  if (inArtistId) {
    sessionStorage.setItem('bb_segment_artist_id',inArtistId);
  }
  if (inActiveArtist) {
    sessionStorage.setItem('bb_segment_active_artist',JSON.stringify({body: inActiveArtist}));
  }
}

const updateArtist = (inArtistId) => {
  if (inArtistId) {
    sessionStorage.setItem('bb_segment_artist_id',inArtistId);
  }
}

const getDataAPI = async (key,value,inActiveArtist,inEmail,complex) => {
  try {
    const requestURL = `${API_URL}${SEGMENT_DATA}${key && value ? `?${encodeURIComponent(key)}=${encodeURIComponent(value)}` : ''}`;
    const res = await axios.get(requestURL);
    if (complex) {
      return res?.data?.data || null;
    } else {
      const activeArtist = inActiveArtist || JSON.parse(sessionStorage.getItem('bb_segment_active_artist'))?.body || null;
      const artists = res?.data?.data?.artists || null;
      return {
        ...activeArtist && artists ? {artistId: activeArtist ? artists.find(a => a?.name?.toLowerCase() === activeArtist?.toLowerCase())?.id || null : null} : {},
        ...!(!res?.data?.data?.isTest && res?.data?.data?.isTest !== false) ? {isTest: res?.data?.data?.isTest} : {isTest: null},
      }
    };
  } catch (error) {
    if (complex) {
      return null;
    } else {
      return {
        artistId: sessionStorage.getItem('bb_segment_artist_id') || null,
        isTest: inEmail ? isTest(inEmail) : null,
      }
    }
  }
}

const getDataStorage = async () => {
  let artistId = '';
  let isTest = false;
  artistId = sessionStorage.getItem('bb_segment_artist_id');
  isTest = parseString(sessionStorage.getItem('bb_segment_is_test'));
  if (!artistId || (!isTest && isTest !== false)) {
    const email = sessionStorage.getItem('bb_segment_email');
    const res = await getDataAPI('email',email);
    artistId = res.artistId;
    isTest = res.isTest;
    if (artistId) {
      sessionStorage.setItem('bb_segment_artist_id',artistId);
    }
    sessionStorage.setItem('bb_segment_is_test',isTest);
  }
  return {
    ...artistId ? {artistId: artistId} : {},
    ...!(!isTest && isTest !== false) ? {isTest: parseBoolean(isTest)} : {isTest: null},
  };
}

const getData = async (api,options) => {
  if (options.inActiveArtist) {
    setData(null, null, null, options.inActiveArtist)
  }
  if (api) {
    const {key,value,inActiveArtist,complex} = options;
    return await getDataAPI(key,value,inActiveArtist,complex);
  } else {
    return await getDataStorage();
  }
}

const getConsentData = () => {
  const termlyData = JSON.parse(localStorage.getItem('TERMLY_API_CACHE'));
  if (!termlyData && termlyData !== false) {
    return true;
  } else {
    return termlyData?.TERMLY_COOKIE_CONSENT?.value?.analytics ? true : false;
  }
}

const minMaxAdvance = (data) => {
  let minAdvance = -1;
  let maxAdvance = -1;
  if (data?.advanceData || data?.advanceData?.[data?.state?.selectedPartner]) {
    const initialAdvanceData = data?.advanceData;
    const min = Object.values(initialAdvanceData).map((e) => {
      return e?.min || Object.values(e).map(e1 => {
        return e1?.min || Object.values(e1).map(e2 => {
          return e2?.min || Object.values(e2).map(e3 => {
            return e3?.min || Object.values(e3).map(e4 => {
              return e4?.min
            })
          })
        })
      })
    }).flat(4);
    const max = Object.values(initialAdvanceData).map((e) => {
      return e?.min || Object.values(e).map(e1 => {
        return e1?.min || Object.values(e1).map(e2 => {
          return e2?.min || Object.values(e2).map(e3 => {
            return e3?.min || Object.values(e3).map(e4 => {
              return e4?.min
            })
          })
        })
      })
    }).flat(4);
    minAdvance = Math.min(...min);
    maxAdvance = Math.max(...max);
  }
  return {
    minAdvance: minAdvance,
    maxAdvance: maxAdvance,
  };
}

const advanceBreakdown = (data) => {
  const { signing_advance, upon_release, option_advance } = data.currentData;
  return {
    catalogAdvance: signing_advance,
    newReleaseAdvance: upon_release,
    optionAdvance: option_advance,
  }
}

const USE_API = !getConsentData();
const OPTIONS = {};

const segment = {
  page: (options) => invoke(window, "analytics.page", {
    ...options,
  }),
  identify: async (userId, traits={}, options={}) => {
    const eventData = {
      ...await getData(USE_API,OPTIONS),
      ...traits,
    }
    return invoke(window, "analytics.identify", userId, eventData, {
      ...options,
    })
  },
  track: {
    loggedIn: async (data,options={}) => {
      const eventData = {
        email: get(data, 'updatedFormData.email'),
        userPath: 'fast_v1',
        customerLifecycleStage: 'qualified_lead',
        funnelStage: 'signed_up',
        leadStatus: 'open_multi_artist',
        ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Logged In";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    usedWidget: async (data,options={}) => {
      const eventData = {
        artistName: get(data, "name"),
        userPath: 'fast_v1',
        customerLifecycleStage: 'subscriber',
        isTest: isTest(get(data, 'values.email')),
      };
      const eventName = "Used Widget";
      invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    createdAccount: async (data, options={}) => {
      const eventData = {
        userPath: 'fast_v1',
        customerLifecycleStage: 'qualified_lead',
        agreePrivacyNotice: get(data, 'values.checkAgreement'),
        funnelStage: 'signed_up',
        leadStatus: get(data, 'isExistingUser') ? 'open_multi_artist' : 'new_single_artist',
        isTest: isTest(get(data, 'values.email')),
      };
      const eventName = "Created Account";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    incomeWarning: async (isExistingUser,options={}) => {
      const eventData = {
        userPath: 'fast_v1',
        customerLifecycleStage: 'qualified_lead',
        funnelStage: 'signed_up',
        leadStatus: isExistingUser ? 'open_multi_artist' : 'new_single_artist',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Income Warning";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    incomeOverreported: async (isExistingUser,options={}) => {
      const eventData = {
        userPath: 'fast_v1',
        customerLifecycleStage: 'qualified_lead',
        funnelStage: 'signed_up',
        leadStatus: isExistingUser ? 'open_multi_artist' : 'new_single_artist',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Income Overreported";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    offersPending: async (data, options={}) => {
      const eventData = {
        userPath: 'fast_v1',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Offers Pending";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    viewedQuote:  async (data) => {
      const { minAdvance, maxAdvance } = minMaxAdvance(data);
      invoke(window, "analytics.track", "Viewed Quote", {
        source: "beatBread App",
        ...await getData(USE_API,OPTIONS),
        userPath: "fast_v1",
        minAdvance: minAdvance,
        maxAdvance: maxAdvance,
        selectedMinAdvance: get(data, 'state.currentData.min'),
        selectedMaxAdvance: get(data, 'state.currentData.max'),
      })
    },
    customizedFunding: async (data,options={}) => {
      const { minAdvance, maxAdvance } = minMaxAdvance(data);
      const ab = advanceBreakdown(data);
      const eventData = {
        userPath: 'fast_v1',
        interestedInPublishingAdvance: get(data, 'toggle'),
        adjustedWorks: get(data, 'defaultOffer.display') !== get(data, 'currentData.display'),
        worksVersion: [
          {
            previousValue: get(data, 'defaultOffer.display'),
            adjustedValue: get(data, 'currentData.display'),
          },
        ],
        adjustedTermLength: get(data, 'defaultOffer.term') !== get(data, 'currentData.term'),
        termLengthVersion: [
          {
            previousValue: get(data, 'defaultOffer.term'),
            adjustedValue: get(data, 'currentData.term'),
          },
        ],
        adjustedFlowthrough: get(data, 'defaultOffer.flowthrough') !== get(data, 'currentData.flowthrough'),
        flowthroughVersion: [
          {
            previousValue: get(data, 'defaultOffer.flowthrough'),
            adjustedValue: get(data, 'currentData.flowthrough'),
          },
        ],
        minAdvance: minAdvance,
        maxAdvance: maxAdvance,
        selectedMinAdvance: get(data, 'currentData.min'),
        selectedMaxAdvance: get(data, 'currentData.max'),
        ...ab,
        customerLifecycleStage: 'qualified_lead',
        funnelStage: 'customized_funding',
        leadStatus: 'in_progress',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Customized Funding";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    submitForReview: async (data,options={}) => {
      const eventData = {
        userPath: 'fast_v1',
        reportDetails: get(data, 'files'),
        upload6MonthOfReports: get(data, 'uploadConfirmation'),
        deleteReportFromDistributor: get(data, 'deleteReportFromDistributor'),
        deleteDistributor: get(data, 'deleteDistributor'),
        customerLifecycleStage: 'opportunity',
        funnelStage: 'uploaded_reports',
        leadStatus: 'open_deal',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Submit For Review";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    serviceDown: async (data,options={}) => {
      const eventData = {
        userPath: 'fast_v1',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Service Down";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    artistTooBig: async (data,options={}) => {
      const eventData = {
        userPath: 'fast_v1',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Artist Too Big";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    artistTooSmall: async (data,options={}) => {
      const eventData = {
        userPath: 'fast_v1',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Artist Too Small";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
    artistFailed: async (data,options={}) => {
      const eventData = {
        userPath: 'fast_v1',
          ...await getData(USE_API,OPTIONS),
      };
      const eventName = "Artist Failed";
      return invoke(window, "analytics.track", eventName, eventData, {
        ...options,
      })
    },
  },
  storage: {
    set: (inEmail,inArtistId,inUserId,inActiveArtist) => setData(inEmail,inArtistId,inUserId,inActiveArtist),
    get: (api,options) => getData(api,options),
    update: (inArtistId) => updateArtist(inArtistId),
  },
};

export default segment;