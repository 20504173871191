import React from "react";
import parse from "html-react-parser";

function CustomHTML(props) {

	const parsedHTML = props.html || props.default ? parse(props.html || props.default) : "";

	return (
		<>

			{
				typeof parsedHTML === 'object' ?
					<parsedHTML.type {...parsedHTML.props} className={props.className || ""} style={props.style || {}} />
				:
					<>{parsedHTML}</>

			}
		</>
	)
}

export default CustomHTML;