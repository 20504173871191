import React, { Component } from "react";
import "./SeeMore.scss";


class SeeMore extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
		};
	}

	toggleShow = (override) => {
		this.setState({show: override || !this.state.show});
	}

	render() {
		return (
			<div className="see-more" style={this.props.style || {}}>
				{
					this.props.preLoad ?
						this.state.show ?
							<div className="sm-content" style={{display:'flex'}}>
								{this.props.children}
							</div>
						:
							<div className="sm-content" style={{display:'none'}}>
								{this.props.children}
							</div>
					:
						this.state.show ?
							<div className="sm-content">
								{this.props.children}
							</div>
						:
							<></>
				}
				{
					this.props.close ?
						!this.state.show ?
							<button
								type="button"
								className="sm-button"
								onClick={() => this.toggleShow(true)}
							>
								<hr className="sm-button-strikethrough" />
								<div
									className="sm-button-text"
									style={{width: `${this.props.openTitle?.length || 8}ch`}}
								>
									{this.props.openTitle || 'See More'}
								</div>
								<hr className="sm-button-strikethrough" />
							</button>
						:
							<button
								type="button"
								className="sm-button"
								onClick={() => this.toggleShow(false)}
							>
								<hr className="sm-button-strikethrough" />
								<div
									className="sm-button-text"
									style={{width: `${this.props.closeTitle?.length || 8}ch`}}
								>
									{this.props.closeTitle || 'See Less'}
								</div>
								<hr className="sm-button-strikethrough" />
							</button>

					: !this.state.show ?
						<button
							type="button"
							className="sm-button"
							onClick={() => this.toggleShow(true)}
						>
							<hr className="sm-button-strikethrough" />
							<div
								className="sm-button-text"
								style={{width: `${this.props?.openTitle?.length || 8}ch`}}
							>
								{this.props.openTitle || 'See More'}
							</div>
							<hr className="sm-button-strikethrough" />
						</button>
					:
						<></>

				}
			</div>
		)
	}
}

export default SeeMore;