import React from "react";
import "./SwiperCarousel.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { ReactComponent as LeftArrow } from "../../assets/SVGs/icon_arrow_left.svg";
import { ReactComponent as RightArrow } from "../../assets/SVGs/icon_arrow_right.svg";
import 'swiper/css';
import 'swiper/css/bundle';
import TestimonialsDivider from '../TestimonialsDivider';

SwiperCore.use([Autoplay]);

function SwiperCarousel(props) {

	return (
		<div className="swiper-carousel">
			<Swiper
				className="sc-component"
				modules={[Navigation, Autoplay]}
				slidesPerView="auto"
				loop={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: true,
				}}
				navigation={{
					prevEl: '.sc-previous',
					nextEl: '.sc-next',
					clickable: true,
				}}
			>
					{
						props.artists.map((e,i) => {
							return (
								<SwiperSlide key={i}>
									<div className="sc-divider">
									<TestimonialsDivider color={i % 2 === 1 ? "#8675B8" : "#FE936F"} />
										<div className="sc-slide">
											<div className="sc-image">
											<picture>
												<source srcSet={e.image} media="(min-width: 1366px)" />
												<source srcSet={e.imageSmall} media="(min-width: 768px)" />
												<img className="sc-image-img" src={e.imageSmall} alt="" loading="lazy" />
											</picture>
											</div>
											<div className="sc-content">
												<div className="sc-quote">{e.quote}</div>
												<div className="sc-artist-name" style={{color: i % 2 === 1 ? "#8675B8" : "#FE936F"}}>{e.name}</div>
												<div className="sc-monthly-listeners">{e.monthlyListeners} Monthly Listeners</div>
											</div>
										</div>
									</div>
								</SwiperSlide>
							)
						})
					}
					<div className="sc-nav sc-previous">
						<LeftArrow width="30" />
					</div>
					<div className="sc-nav sc-next">
						<RightArrow width="30" />
					</div>
			</Swiper>
		</div>
	)
}

export default SwiperCarousel;