import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

export const withRouter = (Component) => (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return (
    <Component
      navigate={navigate}
      params={params}
      location={location}
      {...props}
    />
  );
};
