import { useNavigate } from "react-router-dom";
import styles from "./BackButton.module.scss";
import { ReactComponent as LEFT_ARROW_ICON } from "../../assets/SVGs/arrowLeftIcon.svg";


function BackButton() {
    const navigate = useNavigate();
    return (
      <button onClick={() => navigate(-1)} className={styles.returnToSearch}>
        <LEFT_ARROW_ICON />
        <span className={styles.searchText}>Go Back</span>
      </button>
    );
}

export default BackButton;