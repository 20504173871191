import React, { Component } from "react";
import styles from "../ContactUsForm.module.scss";
import Button2 from "../../Button2";
import { ReactComponent as AlertIcon } from "../../../assets/SVGs/Alert.svg";

class FormError extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
        <div className={styles.contactUsForm}>
            <div className={styles.errIcon}>
                <AlertIcon />
            </div>
            <div className={styles.errText}>
                <div className={styles.title}>Error!</div>
                <div className={styles.subtitle}>
                Your form failed to submit!
                <div>Try again in a few minutes.</div>
                </div>
            </div>
            <Button2 full onClick={this.props.onClick}>
            RETURN TO FORM »
            </Button2>
        </div>
    );
  }
}

export default FormError;
