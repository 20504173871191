import React, { Component } from "react";
import "./Widget.scss";
import {
	WIDGET_RENDER,
} from "./constants";

class Widget extends Component {
	constructor() {
    super();
    this.widgetRenderArea = React.createRef();
  }


	addScript = () => {
		const frame = this.widgetRenderArea.current.contentWindow.document;
		frame.open();
		frame.write(WIDGET_RENDER);
		frame.close();
	};



	componentDidMount() {
		this.addScript();
		if (process.env.REACT_APP_ENV === 'local') {
			document.getElementById('widgetRenderArea').contentWindow.document.getElementsByTagName('html')[0].setAttribute('style','--cta-color:#5737d0; --primary-color:#5637d0;');
		}
	}

	render() {
		
		const hwStyles = {
			...this.props.height ? {height: this.props.height} : {},
			...this.props.width ? {minWidth: this.props.noPadding ? `${parseInt(this.props.width) - 40}px` : this.props.width} : {},
		}
		const styles = {
			...this.props.style || {},
			...this.props.noPadding ? {padding: '20px'} : {},
			...hwStyles,
		}
		const getFundedStyles = {
			...this.props.getFundedStyle || {},
			...hwStyles,
		}
		const widgetRenderAreaStyles = {
			...this.props.widgetRenderAreaStyle || {},
			...hwStyles,
		}
		return (
			<>
				<div className="widget" style={styles}>
					<div id="getFunded" className={`getFundedContainer getFundedContainer${this.props.noPadding ? '-no-padding' : '-normal'}`} style={getFundedStyles}>
            <iframe
              title="Widget"
              id="widgetRenderArea"
              ref={this.widgetRenderArea}
							style={widgetRenderAreaStyles}
            ></iframe>
					</div>
				</div>
			</>
		)
	}
}

export default Widget;