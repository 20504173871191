import React from "react";
import { useNavigate } from "react-router-dom";


function Navigator(props) {
  const navigate = useNavigate();
  return (
    <>{React.cloneElement(props.children,{navigate: navigate})}</>
  );
}

export default Navigator;